<template>
    <mercur-card class="full-height-layout fill mx-4 mb-3">
        <div class="flex px-2 justify-content-between align-items-center">
            <h1>Users</h1>
            <div class="text-right">
                <mercur-button :to="addSupplierUserRoute" class="btn btn-raised btn-yellow text-uppercase">
                    <i class="fas fa-plus"></i>
                    <span>Add new user</span>
                </mercur-button>
            </div>
        </div>

        <template v-if="url">
            <data-table
                class="fill full-height-layout border"
                :options="options"
                :url="url"
                ref="table"
            />
        </template>
        <div>
            <mercur-dialog :is-open.sync="dialog.isOpen">
                <p>{{dialog.content}}</p>
                <template slot="footer">
                    <mercur-button class="btn btn-raised" @click="dialog.isOpen = false">Cancel</mercur-button>
                    <mercur-button class="btn btn-raised btn-primary" @click="dialog.action">Confirm</mercur-button>
                </template>
            </mercur-dialog>
        </div>
    </mercur-card>
</template>
<script>
import DataTable from '@/components/DataTable'
import CONFIG from '@root/config'
export default {
    name: 'UsersOverview',
    components: { DataTable },
    data () {
        return {
            url: null,
            dialog: {
                isOpen: false,
                action: () => {},
            },
            isLoading: false,
            activeAccount: null,
            options: {
                columns: {
                    'Name': {
                        field: 'lastName',
                        valueGetter: ({ data }) => {
                            return `${data.firstName} ${data.lastName}`
                        },
                        sortable: true,
                        link: (value, data) => {
                            return {
                                name: 'SupplierEditUser',
                                params: {
                                    ...this.$route.params,
                                    userId: data.accountId,
                                },
                            }
                        },
                    },
                    'Account type': {
                        field: 'accountType',
                    },
                    'Email': {
                        field: 'email',
                    },
                    'Username': {
                        field: 'username',
                    },
                    'Phone': {
                        field: 'phone',
                    },
                    'organisationId': {
                        hide: true,
                        field: 'organisationId',
                    },
                },
                actions: [
                    {
                        text: 'Revoke',
                        icon: 'fas fa-user-minus',
                        tooltipText: 'Revoke',
                        hide: ({ data }) => {
                            return data.dateRevoked !== null
                        },
                        onClick: ({ data }) => {
                            this.triggerRevoke(data)
                        },
                        disabled: () => {
                            return this.isLoading
                        },
                    },
                    {
                        text: 'Reactivate',
                        icon: 'fas fa-user-check',
                        tooltipText: 'Reactivate',
                        hide: ({ data }) => {
                            return data.dateRevoked === null
                        },
                        onClick: ({ data }) => {
                            this.triggerReactivate(data)
                        },
                        disabled: () => {
                            return this.isLoading
                        },
                    },
                ],
            },
        }
    },
    computed: {
        addSupplierUserRoute () {
            return {
                name: 'SupplierAddUser',
                params: {
                    ...this.$route.params,
                },
            }
        },
    },
    methods: {
        triggerRevoke (data) {
            this.activeAccount = data
            this.dialog = {
                isOpen: true,
                content: `Are you sure that you want to revoke access for ${data.firstName} ${data.lastName}?`,
                action: this.revokeAccount,
            }
        },
        triggerReactivate (data) {
            this.activeAccount = data
            this.dialog = {
                isOpen: true,
                content: `Are you sure that you want to reactivate access for ${data.firstName} ${data.lastName}?`,
                action: this.reactivateAccount,
            }
        },
        revokeAccount () {
            const url = CONFIG.API.ROUTES.ACCESS_CONTROL.ACCOUNT.REVOKE.replace('{accountId}', this.activeAccount.accountId)
            this.isLoading = true
            this.addJob(url)
            this.$api.post(url, this.activeAccount).then(({ data }) => {
                this.$refs.table.refreshGrid()
                this.$root.$emit('notification:global', {
                    message: 'Account was revoked',
                })
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Revoking failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.isLoading = false
                this.finishJob(url)
            })
        },
        reactivateAccount () {
            const url = CONFIG.API.ROUTES.ACCESS_CONTROL.ACCOUNT.REACTIVATE.replace('{accountId}', this.activeAccount.accountId)
            this.isLoading = true
            this.addJob(url)
            this.$api.post(url, this.activeAccount).then(({ data }) => {
                this.$refs.table.refreshGrid()
                this.$root.$emit('notification:global', {
                    message: 'Account was reactivated',
                })
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Reactivating failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.isLoading = false
                this.finishJob(url)
            })
        },
    },
    created () {
        this.supplierData.then(supplierDetails => {
            this.url = CONFIG.API.ROUTES.ACCESS_CONTROL.OVERVIEW_FOR_ORGANISATION.replace('{organisationId}', supplierDetails.organisationId)
        })
    },
}
</script>
